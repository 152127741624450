// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

function AvatarCell({ image, name, color, size }) {
    return (
        <MDBox display="flex" alignItems="center">
            <MDBox>
                <MDAvatar bgColor={color} src={image} alt={name} size={size} />
            </MDBox>
        </MDBox>
    );
}

// Setting default value for the props of AvatarCell
AvatarCell.defaultProps = {
    image: "",
    color: "dark",
    size: "sm",
};

// Typechecking props for the AvatarCell
AvatarCell.propTypes = {
    image: PropTypes.string,
    color: PropTypes.oneOf([
        "transparent",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

export default AvatarCell;
