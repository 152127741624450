import { useContext, useEffect, useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import toast from "react-hot-toast";

// @mui material components
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Chip from "@mui/material/Chip";
import MDButton from "components/MDButton";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// NewUser page components
import FormField from "components/FormField";

// context
import AuthContext from "context/Authcontext";
import { Password } from "@mui/icons-material";

function UserForm({ formData, detail, setFieldValue, userId }) {
  const { getRoles, roleData } = useContext(AuthContext);
  const [roleOption, setRoleOption] = useState([]);
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, email, role, subscription, password } = formField;
  const [showPassword, setShowPassword] = useState(false);
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    role: roleV,
    subscription: subscriptionV,
    password: passwordV,
    affiliate_onboarding_status,
    stripe_coupon_code,
  } = values;

  useEffect(() => {
    async function fetchRoles() {
      if (!roleData || roleData.length === 0) {
        await getRoles();
      }
    }

    fetchRoles();
  }, []);

  useEffect(() => {
    if (roleData && roleData.length > 0) {
      let roleArr = roleData.map((role) => ({
        id: role.id,
        label: role?.name[0].toUpperCase() + role?.name.slice(1),
      }));
      setRoleOption(roleArr);
    }
  }, [roleData]);

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV?.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV?.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV?.length > 0 && !errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disableClearable={true}
              options={roleOption}
              value={roleOption?.find((option) => option.id == roleV) || ""}
              onChange={(event, value) => {
                if (value?.id) {
                  setFieldValue("role", value?.id);
                } else {
                  setFieldValue("role", "");
                }
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={role.label} />
              )}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name={role.name} />
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{
                  color: "#7b809a",
                  fontSize: "12px",
                  fontWeight: "normal",
                }}
              >
                {subscription.label}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={subscription.name}
                value={subscriptionV}
                onChange={(event, value) => {
                  setFieldValue("subscription", value);
                }}
              >
                <FormControlLabel
                  value="trial"
                  control={<Radio />}
                  label={
                    <span
                      style={{
                        color: "#000",
                        fontWeight: "normal",
                      }}
                    >
                      Trial
                    </span>
                  }
                />
                <FormControlLabel
                  value="active"
                  control={<Radio />}
                  label={
                    <span
                      style={{
                        color: "#000",
                        fontWeight: "normal",
                      }}
                    >
                      Active
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControlLabel
                control={
                  <Checkbox
                    checked={showPassword}
                    onChange={(event) => { 
                      setShowPassword(event.target.checked);
                      setFieldValue("password", "");
                    }}
                    name={detail === "Add User" ? "createPassword" : "updatePassword"}
                  />
                }
                label={<span
                      style={{
                        color: "#000",
                        fontWeight: "normal",
                      }}
                    >
                      {detail === "Add User" ? "Create Password" : "Update Password"}
                    </span>}
              />
            {showPassword && (
              <FormField
                type={password.type}
                label={password.label}
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV?.length > 0 && !errors.password}
              />
            )}
          </Grid>
        </Grid>
        {roleV === 2 && (
          <Grid container spacing={3} pt={1}>
            <Grid item xs={12} sm={6}>
              <MDBox display="flex" alignItems="center" py={1} pr={2}>
                <MDTypography variant="button" fontWeight="regular" textTransform="capitalize">
                  Coupon Code: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{stripe_coupon_code ? stripe_coupon_code : ""}
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  <ContentCopyIcon
                    className="copy-icon"
                    onClick={() => {
                      navigator.clipboard.writeText(stripe_coupon_code ? stripe_coupon_code : "");
                      toast.success("Affiliate code copied.");
                    }}
                  />
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox display="flex" alignItems="center" py={1} pr={2}>
                <MDTypography variant="button" fontWeight="regular" textTransform="capitalize">
                  Onboarding Status: &nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;
                  {affiliate_onboarding_status && (
                    <Chip label={affiliate_onboarding_status} color="info" />
                  )}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserForm
UserForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserForm;
