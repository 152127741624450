import { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "context/Authcontext";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import API_ENDPOINTS from "apiConfig";
import { useParams } from "react-router-dom";
import LeftMessage from "./LeftMessage";
import RightMessage from "./RightMessage";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import TextField from "@mui/material/TextField";
import {
  capitalizeFirstLetter,
  convertDateTimeInHumanReadableFormat,
  calculateDateDifference,
} from "lib/helper";
import { Button, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { FaUpload } from "react-icons/fa6";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { IoSend } from "react-icons/io5";
import { useMaterialUIController } from "context";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { position } from "stylis";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { BiSolidImageAdd } from "react-icons/bi";
import { FaArrowDown } from "react-icons/fa";

const ListMessages = ({ messages, profileDetails, endUserDetail, profileImage }) => {
  let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  let profileUserName = profileDetails?.full_name
    ? profileDetails.full_name.charAt(0).toUpperCase() +
      profileDetails.full_name.slice(-1).toUpperCase()
    : "";
  let endUserName = endUserDetail?.full_name
    ? endUserDetail.full_name.charAt(0).toUpperCase() +
      endUserDetail.full_name.slice(-1).toUpperCase()
    : "";

  endUserName = endUserName.length === 0 ? "AD" : endUserName;
  let currentDate = "";
  const messagelist = messages.map((message, index) => {
    let image = message.image ? process.env.REACT_APP_BACKEND_URL + "/" + message.image : null;
    let dateTime = convertDateTimeInHumanReadableFormat(message.created_at, timeZone);
    let noOfDays = calculateDateDifference(dateTime.date);
    let timeString = dateTime.date;
    if (noOfDays === 0) {
      timeString = "Today";
    } else if (noOfDays === 1) {
      timeString = "Yesterday";
    }

    if (dateTime.date !== currentDate) {
      currentDate = dateTime.date;

      return (
        <>
          <MDBox
            key={index}
            mb={3}
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <MDBadge
              variant="contained"
              color="secondary"
              badgeContent={timeString}
              container
              className="message-text-light"
            />
          </MDBox>
          {message.sender_id === profileDetails.id ? (
            <MDBox width="100%">
              <RightMessage
                message={message.message}
                avatar={profileImage}
                name={profileUserName}
                time={dateTime.time}
                image={image}
              />
            </MDBox>
          ) : (
            <LeftMessage
              message={message.message}
              avatar={endUserDetail?.image_path}
              name={endUserName}
              time={dateTime.time}
              image={image}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {message.sender_id === profileDetails.id ? (
            <MDBox width="100%">
              <RightMessage
                message={message.message}
                avatar={profileImage}
                name={profileUserName}
                time={dateTime.time}
                image={image}
              />
            </MDBox>
          ) : (
            <LeftMessage
              message={message.message}
              avatar={endUserDetail?.image_path}
              name={endUserName}
              time={dateTime.time}
              image={image}
            />
          )}
        </>
      );
    }
  });

  return <>{messagelist}</>;
};

function Messages() {
  const { verifyToken, getProfileDetails, profileDetails, profileImage, getUserById } =
    useContext(AuthContext);
  const [support, setSupport] = useState(null);
  const [messages, setMessages] = useState(null);
  const [endUserDetail, setEndUserDetail] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const filePickerRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();
  const messageTextBoxRef = useRef();

  useEffect(() => {
    verifyToken();

    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const toggleRefresh = () => {
    setRefresh(false);
  };

  const getSupportById = async () => {
    try {
      let response = await fetch(API_ENDPOINTS.getSupportBySupportId + `${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        setSupport(jsonResponse.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMessages = async () => {
    try {
      let response = await fetch(API_ENDPOINTS.getSupportMessages + `${id}/messages`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        setMessages(jsonResponse.data);

        let message = jsonResponse.data.find((message) => message.sender_id !== profileDetails.id);
        if (message && !endUserDetail) {
          let endUser = await getUserById(message.sender_id);
          setEndUserDetail(endUser);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateSeenStatus = async () => {
    try {
      let response = await fetch(API_ENDPOINTS.updateSeenStatus, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
        body: JSON.stringify({
          support_id: support.id,
          sender_id: endUserDetail.id,
        }),
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMessage = (e) => {
    setNewMessage(e.target.value);
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
    setPreview(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!(newMessage || uploadedImage)) {
        toast.error("Please add message or upload image");
        return;
      }

      let formData = new FormData();
      formData.append("message", newMessage);
      formData.append("image", uploadedImage);
      formData.append("sender_id", profileDetails.id);
      formData.append("support_id", support.id);
      let response = await fetch(API_ENDPOINTS.createSupportMessage + `${support.id}/messages`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      let jsonResponse = await response.json();
      if (response.ok) {
        toast.success(jsonResponse.message);
        setRefresh(true);
        handleRemoveImage();
        setNewMessage("");
      } else {
        throw new Error(jsonResponse.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.message);
    }
  };

  const triggerImageUpload = () => {
    filePickerRef.current.click();
  };

  const handleMouseDownOnUpload = (event) => {
    event.preventDefault();
  };

  const listenToScroll = () => {
    const totalHeight = document.documentElement.scrollHeight;
    const viewportHeight = window.innerHeight;
    const scrollTop = window.scrollY;
    const scrollBottom = totalHeight - viewportHeight - scrollTop;

    if (scrollBottom > viewportHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const { id } = useParams();
  useEffect(() => {
    getSupportById();
    getMessages();
  }, [id]);

  useEffect(() => {
    if (refresh) {
      getMessages();
      toggleRefresh();
    }

    setTimeout(() => {
      messageTextBoxRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 1000);
  }, [refresh]);

  useEffect(() => {
    if(support && endUserDetail) {
      handleUpdateSeenStatus();
    }
  }, [support, endUserDetail]);

  return (
    <BaseLayout>
      <Card id="Messages" sx={{ position: "relative" }}>
        <MDBox p={3} sx={{ position: "relative" }}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {profileDetails && (
              <MDTypography variant="h6" fontWeight="medium">
                {capitalizeFirstLetter(profileDetails.full_name)}
              </MDTypography>
            )}
            <MDBox
              sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mr: -3 }}
            >
              <MDButton
                color={darkMode ? "white" : "dark"}
                variant="text"
                onClick={() => navigate("/pages/support")}
              >
                <Icon>arrow_back</Icon>&nbsp;Back
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox my={5}>
            {messages && (
              <ListMessages
                messages={messages}
                profileDetails={profileDetails}
                endUserDetail={endUserDetail}
                profileImage={profileImage}
              />
            )}
          </MDBox>
          <MDBox position="relative">
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {preview && (
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width="100%"
                  position="relative"
                >
                  <Card className="message-image-card cursor-pointer" position="relative">
                    <img src={preview} />
                    <MDBox className="remove-img-button">
                      <CloseIcon onClick={handleRemoveImage} fontSize="large" />
                    </MDBox>
                  </Card>
                </MDBox>
              )}
              <MDBox
                ref={messageTextBoxRef}
                mt={preview ? 2 : 0}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <MDBox className="message-text-field" position="relative">
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <OutlinedInput
                      type="text"
                      placeholder="Type a message"
                      endAdornment={
                        <InputAdornment position="end" sx={{ mr: 2, ml: 0 }}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={triggerImageUpload}
                            onMouseDown={handleMouseDownOnUpload}
                            edge="end"
                          >
                            <BiSolidImageAdd />
                          </IconButton>
                        </InputAdornment>
                      }
                      onChange={handleMessage}
                      multiline
                      maxRows={4}
                    />
                  </FormControl>
                  <MDBox
                    component="input"
                    name="file"
                    type="file"
                    single
                    onChange={handleImageChange}
                    ref={filePickerRef}
                    hidden
                  />
                </MDBox>
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "start",
                    width: "68px",
                  }}
                >
                  <MDButton variant="contained" color="info" onClick={handleSubmit}>
                    <IoSend />
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
          {isVisible && (
            <MDBox
              className="scroll-to-bottom-btn"
              onClick={() => {
                messageTextBoxRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              <FaArrowDown color="secondary" />
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BaseLayout>
  );
}

export default Messages;
