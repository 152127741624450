import React, { useEffect, useState, useContext } from "react";

import API_ENDPOINTS from "apiConfig";
import toast from "react-hot-toast";
import { changeDateFormatWithTimeZone } from "lib/helper";
import AuthContext from "context/Authcontext";
import TableContext from "context/Tablecontext";

// mui components
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import Tooltip from "@mui/material/Tooltip";
import DefaultCell from "components/Cell/DefaultCell";
import CustomerCell from "components/Cell/CustomerCell";
import DataTable from "components/Tables/DataTable";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MDMenu from "components/MDMenu";
import MenuItem from "@mui/material/MenuItem";
import MDTable from "components/MDTables";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import FilterMenu from "../../../videos/components/FilterMenu";
import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";

// icons
import Icon from "@mui/material/Icon";
import { ImCross } from "react-icons/im";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { MdArrowDropUp } from "react-icons/md";
import { IoChatbubbleEllipses } from "react-icons/io5";

//functions
import { capitalizeFirstLetter, formatISODate, formateDateString } from "lib/helper";

const statusOptions = [
  { value: "new", label: "New" },
  { value: "in progress", label: "In Progress" },
  { value: "resolved", label: "Resolved" },
];

const ButtonCell = ({ contactUs }) => {
  return (
    <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    <Link to={`${contactUs.id}`}>
      <MDBox
        variant="text"
        sx={{ display: "flex", alignItems: "center" }}
        color="info"
        className="cursor-pointer"
      >
        <IoChatbubbleEllipses />
        &nbsp;
      </MDBox>
      </Link>
    </MDBox>
    
  );
};

const StatusCell = ({ contactUs, setRefresh }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [activeStatus, setActiveStatus] = useState("");
  const [activeColor, setActiveColor] = useState("");

  const handleAction = async (value) => {
    setActiveStatus(value);

    if (value === contactUs.status) {
      toast.error("Please select different status from current status");
      return;
    }
    setRefresh(true);
    let response = await fetch(API_ENDPOINTS.updateSupport + `/${contactUs?.id}`, {
      method: "PUT",
      body: JSON.stringify({ status: value }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
      },
    });

    let jsonResponse = await response.json();
    if (response.ok) {
      toast.success("Status updated successfully");
      setRefresh(false);
    } else {
      throw new Error(jsonResponse || "Something went wrong");
    }
  };

  const menus = statusOptions.map((status) => (
    <MDBox
      className={`dropdown-status-item ${
        status.value === activeStatus ? "active" : ""
      } bg-${activeColor}`}
      key={status.value}
      value={status.value}
      onClick={(event) => {
        event.preventDefault();
        handleAction(status.value);
      }}
    >
      <MDTypography variant="caption" fontWeight="regular">
      {status.label}
      </MDTypography>
    </MDBox>
  ));

  useEffect(() => {
    setActiveStatus(contactUs?.status);
    if (contactUs?.status === "new") {
      setActiveColor("info");
    } else if (contactUs?.status === "in progress") {
      setActiveColor("yellow");
    } else if (contactUs?.status === "resolved") {
      setActiveColor("success");
    }
  }, [contactUs]);

  return (
    <>
      <MDBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <MDBox>
          <MDMenu
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            menuWidth={130}
            triggerElement={
              <>
                <MDBox ml={0}>
                  <MDButton
                    ml={0}
                    variant="gradient"
                    size="small"
                    onClick={() => setOpenMenu(!openMenu)}
                    sx={{ my: 1, width: 135 }}
                    className={`dropdown-status bg-${activeColor}`}
                    px={0} mx={0}

                  >
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <MDBox
                        ml={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "auto",
                        }}
                      >
                        <MDTypography
                          className="dropdown-status-item-typo"
                          color="white"
                          variant="caption"
                          align="left"
                          fontWeight="medium"
                          noWrap
                        >
                          {capitalizeFirstLetter(activeStatus)}
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        sx={{ display: "flex", alignItems: "center", justifyContent: "end", width: "auto" }}
                      >
                        {openMenu ? (
                          <MdArrowDropUp
                            size={20}
                            color="white"
                            className="dropdown-status-arrow"
                          />
                        ) : (
                          <MdOutlineArrowDropDown
                            size={20}
                            color="white"
                            className="dropdown-status-arrow"
                          />
                        )}
                      </MDBox>
                    </MDBox>
                  </MDButton>
                </MDBox>
              </>
            }
          >
            <MDBox>
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={12}>
                  {menus}
                </Grid>
              </Grid>
            </MDBox>
          </MDMenu>
        </MDBox>
      </MDBox>
    </>
  );
};

const List = () => {
  const [refresh, setRefresh] = useState(true);
  const [supportList, setSupportList] = useState([]);
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const { getProfileDetails, profileDetails } = useContext(AuthContext);
  const {
    pageSize,
    search,
    orderBy,
    order,
    currentPage,
    enteries,
    setEnteries,
    isSearching,
    setIsSearching,
    initializeTable,
  } = useContext(TableContext);
  const [canSearch, setCanSearch] = useState(true);
  const [activeTable, setActiveTable] = useState(null);
  const [filteringParams, setFilteringParams] = useState({
    filterByDate: false,
    startDate: "",
    endDate: "",
  });
  const [filterTitle, setFilterTitle] = useState("");

  const handleTabChange = (event, newValue) => {
    setActiveTable(newValue);
    localStorage.setItem("activeSupportTab", newValue);
    setRefresh(true);
  };

  const loadData = (supportList) => {
    if (supportList && supportList.length > 0) {
      let columns = [
        {
          Header: "id",
          accessor: "id",
          align: "left",
          // minWidth: 110,
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "name",
          accessor: "name",
          align: "left",
          // minWidth: 170,
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "email",
          accessor: "email",
          align: "left",
          // minWidth: 170,
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "title",
          accessor: "title",
          align: "left",
          // minWidth: 170,
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "status",
          accessor: "status",
          align: "left",
          // minWidth: 170,
          Cell: ({ value: [value, setRefresh] }) => (
            <StatusCell contactUs={value} setRefresh={setRefresh} />
          ),
        },
        {
          Header: "created_at",
          accessor: "created_at",
          align: "left",
          // minWidth: 170,
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: "action",
          accessor: "action",
          isSorted: false,
          align: "left",
          // minWidth: 100,
          Cell: ({ value }) => <ButtonCell contactUs={value} />,
        },
      ];

      let timeZone = profileDetails?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      let rows = supportList.map((item) => {
        const status = item?.status ? capitalizeFirstLetter(item?.status) : "N/A";
        const name = item?.name ? capitalizeFirstLetter(item?.name) : "N/A";
        const title = item?.title ? capitalizeFirstLetter(item?.title) : "N/A";
        const created_at = changeDateFormatWithTimeZone(item?.created_at, timeZone);
        return {
          id: item.request_id || "N/A",
          name: name,
          email: item.email || "N/A",
          title: title,
          status: [item, setRefresh],
          created_at: created_at || "N/A",
          action: item,
        };
      });

      setDataTable({ columns: columns, rows: rows });
    } else {
      setDataTable({ columns: [], rows: [] });
    }
  };

  const getSupportList = async () => {
    try {
      let query = "";
      query += `&page=${currentPage}&limit=${pageSize}`;

      let filterByDate = localStorage.getItem("filterSupportByDate");
      if (filterByDate) {
        filterByDate = JSON.parse(filterByDate);
        query += `&start_date=${filterByDate.startDate}&end_date=${filterByDate.endDate}`;
      }

      if (search) {
        query += `&search=${search}`;
      }

      if (orderBy && order) {
        let orderByItem = order === "asc" ? orderBy : `-${orderBy}`;
        query += `&sortBy=${orderByItem}`;
      }

      let supportType = activeTable === "bug-reports" ? "bug" : "contact";
      let response = await fetch(API_ENDPOINTS.getSupport + `?type=${supportType}${query}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JSON.parse(localStorage.getItem("skoopCrmAccessToken"))}`,
        },
      });

      let jsonResponse = await response.json();
      if (response.ok) {
        const { data } = jsonResponse;
        setSupportList(data.items);
        loadData(data.items);

        const totalPage = Math.ceil(data.totalItems / pageSize);
        const startIndex = (currentPage - 1) * pageSize + 1;
        const endIndex = Math.min(currentPage * pageSize, data.totalItems);
        setEnteries({
          ...enteries,
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          totalMediaCount: totalPage,
          enteriesStart: startIndex,
          enteriesEnd: endIndex,
        });
      } else {
        throw new Error(jsonResponse || "Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetFilteredDate = () => {
    localStorage.removeItem("filterSupportByDate");
    setFilteringParams({ ...filteringParams, filterByDate: true, startDate: "", endDate: "" });
    setFilterTitle("");
  };

  const addStorageDataInState = () => {
    let filterByDate = localStorage.getItem("filterSupportByDate");
    if (filterByDate) {
      filterByDate = JSON.parse(filterByDate);
      let startDate = formateDateString(filterByDate?.startDate);
      let endDate = formateDateString(filterByDate?.endDate);
      setFilterTitle(`${startDate} - ${endDate}`);
      setFilteringParams((prevParams) => ({
        ...prevParams,
        startDate: filterByDate?.startDate,
        endDate: filterByDate?.endDate,
      }));
    }
  };

  const addFilterDateToStorage = () => {
    if (filteringParams && filteringParams?.startDate && filteringParams?.endDate) {
      let startDate = formateDateString(filteringParams?.startDate);
      let endDate = formateDateString(filteringParams?.endDate);
      setFilterTitle(`${startDate} - ${endDate}`);
      localStorage.setItem(
        "filterSupportByDate",
        JSON.stringify({
          startDate: filteringParams.startDate,
          endDate: filteringParams.endDate,
        })
      );
    }
  };

  const handleStopRefresh = () => {
    setRefresh(false);
  };

  const handleSetActiveTable = (tab) => {
    setActiveTable(tab);
  }

  useEffect(() => {
    if (refresh) {
      const activeTab = localStorage.getItem("activeSupportTab");
      if(activeTab === null) {
        localStorage.setItem("activeSupportTab", "bug-reports");
        handleSetActiveTable("bug-reports");
      } else {
        handleSetActiveTable(activeTab);
      }
      addStorageDataInState();
      initializeTable();
      getSupportList();
      handleStopRefresh(false);
    }
  }, [refresh, activeTable]);

  useEffect(() => {
    if (!refresh) {
      getSupportList();
    }
  }, [pageSize, currentPage, orderBy, order]);

  useEffect(() => {
    if (isSearching) {
      clearTimeout(isSearching);
    }

    const timeoutId = setTimeout(() => {
      if (!refresh) {
        getSupportList();
      }
    }, 500);

    setIsSearching(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    if (!refresh && filteringParams) {
      addFilterDateToStorage();
      getSupportList();
    }
  }, [filteringParams]);

  return (
    <>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center" justifyContent="space-between">
          <Box className="switch-tab-container" sx={{ width: "auto", typography: "body1" }}>
            <TabContext value={activeTable}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TabList onChange={handleTabChange}>
                  <Tab
                    label="Contact Us"
                    value="contact-us"
                    className={`${activeTable === "Contact Us" ? "active" : ""} switch-tab`}
                  />
                  <MDBox
                    className="divider-container"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 0.6,
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      color="secondary"
                      className="vertical-divider"
                    />
                  </MDBox>
                  <Tab
                    label="Bug Reports"
                    value="bug-reports"
                    className={`${activeTable === "bug-reports" ? "active" : ""} switch-tab`}
                  />
                </TabList>
              </Box>
            </TabContext>
          </Box>

          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              mb: { xs: 2.8, sm: 0, md: 0 },
              width: {
                xs: "100%",
                sm: "auto",
                md: "auto",
              },
              justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
                mr: { xs: 0, sm: 0.3, md: 0.3 },
              }}
            >
              {filterTitle && (
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  className="time-slot-btn"
                  sx={{ ml: { xs: 0, sm: 0, md: -3 } }}
                >
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title="Clear Filter"
                    placement="top"
                  >
                    <MDBox mr={1} sx={{ display: "flex", alignItems: "center" }}>
                      <ImCross
                        size={12}
                        color="info"
                        className="icon-color cursor-pointer"
                        onClick={resetFilteredDate}
                      />
                    </MDBox>
                  </Tooltip>
                  <MDBox sx={{ display: "flex", alignItems: "center", position: "relative" }}>
                    <MDTypography variant="button" className="icon-color" fontWeight="bold">
                      {filterTitle}
                    </MDTypography>
                    <MDBox className="line-over-text" />
                  </MDBox>
                </MDButton>
              )}
            </MDBox>
            <MDBox
              sx={{
                ml: { xs: filterTitle ? 1.7 : 0, sm: filterTitle ? 1.7 : 0, md: 1.7 },
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" },
              }}
            >
              <FilterMenu
                filteringParams={filteringParams}
                setFilteringParams={setFilteringParams}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        <MDTable table={dataTable} canSearch={canSearch} />
      </MDBox>
    </>
  );
};

export default List;