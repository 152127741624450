import "./styles/globals.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "context/Authcontext";
import { TableProvider } from "context/Tablecontext";
import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <AuthProvider> 
      <TableProvider>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </TableProvider>
    </AuthProvider>
  </BrowserRouter>
);
