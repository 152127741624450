import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import PageLayout from "examples/LayoutContainers/PageLayout";
import Divider from "@mui/material/Divider";
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import TextField from "@mui/material/TextField";
import MDButton from 'components/MDButton';
import AuthContext from "context/Authcontext";
import { capitalizeFirstLetter } from "lib/helper";
import Chip from '@mui/material/Chip';
import toast from "react-hot-toast";
import Confirmation from "components/ConfirmDelete";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import StripeSecure from 'components/StripeSecure';
import { AppBar, Toolbar } from "@mui/material";
import Link from "@mui/material/Link";
import logo from "assets/crmimages/logo.png";
import IconButton from "@mui/material/IconButton";
import Footer from "examples/Footer";


const ProductCard = ({ products, subscriptionType, couponInfo, handleSubscriptionChange ,couponValid}) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const handleClick = (type) => {
        handleSubscriptionChange(type);
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: blue[500],
            },
        },
    });

    useEffect(() => {
        if (products) {
            if (products.length > 0) {
                let product = products.find((product) => product?.name?.toLowerCase() === 'monthly');
                if (product) {  
                    handleSubscriptionChange(product?.name?.toLowerCase());
                }
            }
        }
    }, [products])

    return (
        <>
            {products && products.map((product) => (
                <Grid item xs={12} key={product?.id}>
                    <Card sx={{ backgroundColor: product.name.toLowerCase() === 'monthly' ? "#eee3fc" : "#ffe6cf" }} onClick={() => handleClick(product?.name?.toLowerCase())}>
                        <MDBox p={2} sx={{ display: "flex", flexDirection: "row" }}>
                            <MDBox sx={{ display: "flex", alignItems: "center" }}>
                                <ThemeProvider theme={theme}>
                                    <Checkbox {...label} id="circleCheckbox" checked={subscriptionType === product?.name?.toLowerCase()}
                                        onChange={() => handleSubscriptionChange(product?.name?.toLowerCase())}
                                        icon={<RadioButtonUncheckedIcon />}
                                        color="primary"
                                        checkedIcon={<CheckCircleIcon />} />
                                </ThemeProvider>
                            </MDBox>
                            <MDBox sx={{ display: "flex", flexDirection: "column" }} ml={2}>
                                        <MDTypography variant="h5" gutterBottom align="left" sx={{ mt: 1, mb: 1 }}>
                                        $ {couponInfo
                                                ? couponInfo.discount?.percent_off
                                                    ? (
                                                        (product.price * (100 - couponInfo.discount.percent_off)) /
                                                        100
                                                    ).toFixed(2)
                                                    : couponInfo.discount?.amount_off
                                                        ? (product.price - couponInfo.discount.amount_off).toFixed(2)
                                                        : product.price
                                                : product.price}{" "}
                                            {capitalizeFirstLetter(product?.name)} 
                                            {product.name.toLowerCase() === 'yearly' && (<Chip label="Save 20%" fontWeight="regular" fontSize="medium" sx={{ backgroundColor: "#ffc107", fontSize: "1rem", ml: "5px", fontWeight: "bold" }} />)}
                                        </MDTypography>
                                        {couponValid && <MDTypography variant="h5" fontWeight="regular" gutterBottom align="left" sx={{ mt: 1, mb: 1 }}>
                                        Original Amount  : $ {product.price}
                                        </MDTypography>}

                            </MDBox>
                        </MDBox>
                    </Card>
                </Grid>
            ))}
        </>
    );
}


const Subscription = () => {
    const { createSubscription, getMySubscription, verifyCoupon, subscriptionType, setSubscriptionType, getProducts, products, setProducts, createAppSumoSubscription, verifyToken, isPro, isAutheticated, gracePeriod, setGracePeriod, handleLogout, recieveVerificationMail, skoopUsername ,sessionUrl, setSessionUrl,couponCode, setCouponCode,couponValid, setCouponValid,couponInfo, setCouponInfo,showConfirmationModal, setShowConfirmationModal,couponType, setCouponType} = useContext(AuthContext);


    const navigate = useNavigate();
    const handleSubscriptionChange = (type) => {
        setSubscriptionType(type);
    };

    const handleCreateSubscription = async () => {
        if(couponType === 'appsumo') {
            createAppSumoSubscription(couponCode);
        }
        else {
            if (!subscriptionType) {
                return toast.error('Please Select Subscription Type');
            }
            const today = new Date()
            const startDate = today.toISOString().split('T')[0]
            let subsData = {
                start_date: startDate,
                plan_type: subscriptionType,
                crm:true
            }
            if (couponCode.length >= 3 && couponValid) {
                subsData.coupon = couponCode.trim()
            }
            const session = await createSubscription(subsData)
            console.log(session)
            setSessionUrl(session?.url)
        }
    }

    useEffect(() => {
        (async () => {
            const response = await getMySubscription();
            const verifyTokenResponse = await verifyToken();
        })()
    }, [])

    const handleCouponValidation = async () => {
        setCouponInfo(null);
        if (couponCode.length <= 1) {
            toast.error('Please Enter Valid Coupon Code')
            return
        }
        const couponValidation = await verifyCoupon(couponCode)
        const response = await couponValidation.json();
        if (couponValidation.ok) {
            toast.success('Coupon Applied');
            setCouponValid(true)
            
            if(response?.discount) {
                setCouponType('stripe');
            }
            else if(response?.lifeTimeCoupon){
              setCouponType('lifetime');
            }
            else {
                setCouponType('appsumo');
            }
            setCouponInfo(response);
        } else {
            setCouponInfo(null);
            setCouponType('');
            setCouponValid(false)
            toast.error('Invalid Coupon');
        }
    }

    const handleInputChange = (event) => {
        // Update the 'couponCode' state with the input's current value
        setCouponInfo({});
        setCouponValid(false);
        setCouponCode(event.target.value)
    }

    const handleConfirmation = (confirm) => {
        if (confirm) {
            handleCreateSubscription();
        }
        setShowConfirmationModal(false)
    }

    const handleVerificationEmail = () => {
        if(skoopUsername && skoopUsername.length > 0) {
            recieveVerificationMail(skoopUsername);
        } 
        else {
           let userEmail = localStorage.getItem('skoopUsername');
            recieveVerificationMail(userEmail);
        }
    }

    useEffect(() => {
        (async () => {
          const res = await verifyToken(false);
          if (!res.ok) {
            navigate("/authentication/sign-in");
          }
          if(res.ok ) {
            if (isPro) {
              navigate("/pages/profile/profile-overview");
            }
          }
          getProducts();
          
        })();
      }, [isAutheticated, isPro]);

    return (
      <MDBox className="page-container">
        <AppBar position="static" color="info">
          <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
            <Link href="https://skoopapp.com/" style={{ textDecoration: "none" }} sx={{ flex: 1, }}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <img src={logo} alt="logo" style={{ width: 80 }} />
                <MDTypography
                  variant="h2"
                  fontWeight="medium"
                  color="white"
                  style={{ cursor: "pointer" }}
                >
                  Skoop
                </MDTypography>
              </IconButton>
            </Link>
            <MDTypography
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: { xs: "center", sm: "center", md: "center", lg: "center" },
              }}
              variant="h4"
              fontWeight="medium"
              color="white"
            >
              Instant Video for LinkedIn & More
            </MDTypography>
            <MDBox
              sx={{
                flex: { xs: 1, sm: 1, md: 1, lg: 1 },
                display: "flex",
                justifyContent: "flex-end",
              }}>
            <MDButton color="inherit" onClick={handleLogout}>Logout</MDButton>
            </MDBox>
          </Toolbar>
        </AppBar>
        {gracePeriod > 0 && (
          <div
            style={{
              position: "relative",
              top: 0,
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#fff3cd",
              color: "#f40606",
              padding: "10px",
              border: "1px solid red",
              borderRadius: "5px",
              zIndex: 1000,
              width: "fit-content",
              maxWidth: "90%",
              textAlign: "center",
            }}
          >
            <p style={{ whiteSpace: "pre-wrap", margin: 0 }}>
              Verify your email in {gracePeriod} days to continue. <span className="text-decoration-underline" onClick={handleVerificationEmail}>Click here to resend email</span>.
            </p>
          </div>
        )}

        <MDBox py={3}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <MDTypography variant="h4" gutterBottom align="center" sx={{ mt: 1, mb: 3 }}>
                Select your plan 
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ height: "100%", mb: 3 }}>
                <MDBox p={3}>
                  <MDTypography variant="body1" gutterBottom>
                    Unlock all the premium benfits now.
                  </MDTypography>
                  <MDBox mt={2}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      gutterBottom
                      align="left"
                      sx={{ mb: 1 }}
                    >
                      🔓 Accessibility to all features
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      gutterBottom
                      align="left"
                      sx={{ mb: 1 }}
                    >
                      ✉️ Send unlimited messages
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      gutterBottom
                      align="left"
                      sx={{ mt: 1 }}
                    >
                      ⚡ Faster AI generated responses
                    </MDTypography>
                  </MDBox>
                  <Divider />
                  <Grid container rowSpacing={2}>
                    {couponValid && (couponType =="appsumo" || couponType =="lifetime") ?couponType =="appsumo"?<Chip
                          label="Welcome Appsumo User"
                          fontWeight="regular"
                          fontSize="medium"
                          sx={{
                            backgroundColor: "#ffc107",
                            fontSize: "0.9rem",
                            ml: "5px",
                            fontWeight: "bold",
                            width: "100%",
                            height: "24px",
                          }}
                        />:"":products && products?.length > 0 && (
                      <ProductCard
                        products={products}
                        subscriptionType={subscriptionType}
                        couponInfo={couponInfo}
                        handleSubscriptionChange={handleSubscriptionChange}
                        couponValid={couponValid}
                      />
                    )}
                  </Grid>
                  <Grid
                    container
                    sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: "center" }}
                    columnSpacing={2}
                  >
                    <Grid item xs={8} md={9}>
                      <TextField
                        label="Enter your coupon code"
                        variant="outlined"
                        type="code"
                        fullWidth
                        value={couponCode}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <MDButton
                        color={couponValid ? "success" : "primary"}
                        type="button"
                        onClick={handleCouponValidation}
                        sx={{ width: "100%" }}
                      >
                        {couponValid ? "Applied" : "Apply"}
                      </MDButton>
                    </Grid>
                  </Grid>

                  {couponInfo && couponValid && (
                    <MDBox my={1} mx={0} px={0}>
                      {couponType == "appsumo" || couponType == "lifetime" && (
                        <Chip
                          label="Lifetime Access"
                          fontWeight="regular"
                          fontSize="medium"
                          sx={{
                            backgroundColor: "#ffc107",
                            fontSize: "0.9rem",
                            ml: "5px",
                            fontWeight: "bold",
                            width: "100%",
                            height: "24px",
                          }}
                        />
                      )}
                      {couponInfo?.discount && (
                        <Chip
                          label={`Discount of ${
                            couponInfo?.discount?.amount_off
                              ? couponInfo?.discount?.amount_off + "$"
                              : couponInfo?.discount?.percent_off + "%"
                          }   ${
                            couponInfo?.discount?.frequency_type == "repeating"
                              ? "for " + couponInfo?.discount?.duration_in_months + " months."
                              : couponInfo?.discount?.frequency_type == "once"
                              ? "on first billing cycle."
                              : "on every billing cycle."
                          }`}
                          fontWeight="regular"
                          fontSize="medium"
                          sx={{
                            backgroundColor: "#ffc107",
                            fontSize: "0.9rem",
                            ml: "5px",
                            fontWeight: "bold",
                            width: "100%",
                            height: "24px",
                          }}
                        />
                      )}
                      {couponInfo?.discount?.trial_period && (
                        <Chip
                          label={`Extended Trial period to ${couponInfo?.discount.trial_period} days.`}
                          fontWeight="regular"
                          fontSize="medium"
                          sx={{
                            backgroundColor: "#ffc107",
                            fontSize: "0.9rem",
                            ml: "5px",
                            fontWeight: "bold",
                            width: "100%",
                            height: "24px",
                          }}
                        />
                      )}
                    </MDBox>
                  )}
                  <MDBox mt={2}>
                    <MDButton
                      variant="contained"
                      color="info"
                      size="medium"
                      sx={{ width: "100%" }}
                      onClick={() => {
                        if (couponValid) {
                          handleCreateSubscription();
                        } else {
                          setShowConfirmationModal(true);
                        }
                      }}
                    >
                      {couponType === "appsumo" || couponType === "lifetime" 
                        ? `ACTIVATE LIFE TIME ACCESS`
                        : `${
                            couponInfo?.discount?.trial_period
                              ? "START "  + couponInfo?.discount.trial_period + "-DAY FREE TRIAL"
                              : "MAKE PAYMENT "
                          }`}
                    </MDButton>
                  </MDBox>
                  {couponValid && (couponType =="appsumo" || couponType =="lifetime") ?"":products && products?.length > 0 && (
                          <StripeSecure />
                    )}
                  
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Confirmation
          title="Subscription with coupon"
          message="Are you sure you don't have a coupon to apply?"
          confirm={handleConfirmation}
          showDialog={showConfirmationModal}
        />
        <Footer />
      </MDBox>
    );
}

export default Subscription;
